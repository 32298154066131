let cdnjs = {
    "touchswipe": "https://cdnjs.cloudflare.com/ajax/libs/jquery.touchswipe/1.6.18/jquery.touchSwipe.min.js",
    "headroom": "https://cdnjs.cloudflare.com/ajax/libs/headroom/0.9.4/headroom.min.js",
    "picturefill": "https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.3/picturefill.min.js",
    "stickyfill": "https://cdnjs.cloudflare.com/ajax/libs/stickyfill/2.0.5/stickyfill.min.js",
    "dropzone": "https://cdnjs.cloudflare.com/ajax/libs/dropzone/5.1.1/min/dropzone.min.js",
    "autosize": "https://cdnjs.cloudflare.com/ajax/libs/autosize.js/4.0.2/autosize.min.js",
    "croppie": "https://cdnjs.cloudflare.com/ajax/libs/croppie/2.6.2/croppie.min.js",
    "croppie_css": "https://cdnjs.cloudflare.com/ajax/libs/croppie/2.6.2/croppie.min.css",
    "clippingmagic": "https://clippingmagic.com/api/v1/ClippingMagic.js"
};