(function ($) {
    fn_nl_lib_anchor();

    nl_lib_dialog.init(function () {
        doc.find(".lib--dialog .part_ui_btn").nl_lib_ripple();
    });

    function fn_disable_resize() {
        if (win.width() < 1024) {
            body.addClass("mod--disabled");
        } else {
            body.removeClass("mod--disabled");
        }
    }

    fn_disable_resize();
    win.on("resize", fn_disable_resize);


    $(".part_ui_btn").nl_lib_ripple();

    $.getScript(cdnjs.autosize).done(function () {
        autosize($('textarea:not(.autosize-disabled)'));
    });

    doc.on('click', '[href].ajax', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });

    doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
        e.preventDefault();
        let frm = $(this),
            formData = new FormData($(this)[0]);

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });

    doc.on('click', '[data-component-ajax]', function (e) {
        e.preventDefault();
        let url = $(this).attr('data-href');
        if (!url || !url.length) {
            url = $(this).attr('href');
        }

        if (!url || !url.length) {
            console.log('no href attribute for ajax component link');
            return;
        }

        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajaxHandler(payload);
        });
    });

    let picture = document.createElement('picture'),
        strObj = '' + picture;

    if (strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie")) {
        $.getScript(cdnjs.picturefill);
    }

    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        html.addClass("ie11");

        $.getScript(cdnjs.stickyfill).done(() => {
            let stickies = $(".mod--sticky");
            Stickyfill.add(stickies);
        })
    }

    doc.on('change', '[data-change-record]', function () {
        var $elm = $(this);
        var value = $elm.val();

        if ($elm.is(':checkbox')) {
            value = $elm.prop('checked') ? 1 : 0;
        }

        if ($elm.attr('type') === 'file') {
            var fd = new FormData();
            fd.append('do', 'adminChange');
            fd.append('id', $elm.closest('tr').data('id'));
            fd.append('col', $elm.attr('name'));
            fd.append('val', value);
            fd.append('image', $elm[0].files[0]);

            $.ajax({
                method: 'post',
                data: fd,
                cache: false,
                contentType: false,
                processData: false
            }).done(function () {
                window.location = location.href;
            });
        } else {
            $.ajax({
                method: 'post',
                data: {do: 'adminChange', id: $elm.closest('tr').data('id'), col: $elm.attr('name'), val: value}
            });
        }
    });

    doc.on('click', '[data-save-template]', function () {
        var $form = $('#template-form');
        var html = $('[data-html]').html();

        $form.find('[name="html"]').val(html);
        $form.find('[type="submit"]').click();

        if ($form[0].checkValidity()) {
            $(this).addClass('mod--loading');
        }
    });

    doc.on('click', '[data-template-download]', function (e) {
        e.preventDefault();
        var $elm = $(this);
        var $form = $("#template-download-form");
        var html = $('[data-html]').html();

        $form.attr('action', $elm.data('template-download'));
        $form.find('[name="html"]').val(html);
        $form.submit();
    });

    doc.on('change', '[data-select-count]', function () {
        var elm = $(this);

        $.ajax({
            data: {do: 'getPrice', count: $(this).val(), templateId: elm.data('select-count')},
            method: 'GET',
            dataType: 'json'
        }).done(function (payload) {
            $('[data-count-price]').text(payload.price);
        });
    });
})(jQuery);