/*global doc */
/*global cdnjs */
/*global nl_lib_dialog */
/*global ClippingMagic */
/*global body */

(function fn() {
    $.getScript(cdnjs.clippingmagic);

    const selector = $(".comp_edit");
    window.lui.comp_edit = fn;
    window.lui.comp_edit.selector = selector;
    let image_edit = null;
    let image_output_width = null;
    let image_output_height = null;
    let image_output_same = null;
    let template_id = null;

    function CheckQrVisible() {
        var qrCeckbox = $('input[name="add_qr_code"]');
        if(qrCeckbox.length) {
            if(qrCeckbox.prop('checked') === true) {
                $('.wrp_part_qr').show();
            } else {
                $('.wrp_part_qr').hide();
            }
        }
    };

    CheckQrVisible();

    $(document).on('change','input[name="add_qr_code"]', function() {
        CheckQrVisible();
    });

    if (selector.length) {
        selector.on("input", "[data-text-edit]", function () {
            let el = $(this);
            let id = el.data("text-edit");
            let val = el.val();
            let settings = el.data("text-settings");
            let template = selector.find(`[data-template-edit="${id}"]`);
            let prefix = el.data('prefix');
            let suffix = el.data('suffix');

            if (id === "head_heading") {
                if (val.length > 24) {
                    template.parent().addClass("is--small");
                } else {
                    template.parent().removeClass("is--small");
                }
            }

            if (val && prefix) {
                if (!val.startsWith(prefix)) {
                    val = prefix + val;
                }
            }

            if (val && suffix) {
                if (!val.endsWith(suffix)) {
                    val += suffix;
                }
            }

            if (val.length === 0) {
                template.closest("[data-template-edit-parent]").hide();
            } else {
                template.closest("[data-template-edit-parent]").show();
            }

            if (typeof settings !== "undefined") {
                if (val.length > settings["small_font"]) {
                    template.addClass("mod--small").closest(".wrp_part_head").addClass("mod--small");
                } else {
                    template.removeClass("mod--small");
                    if (template.closest(".wrp_part_head").find(".mod--small").length === 0) {
                        template.closest(".wrp_part_head").removeClass("mod--small");
                    }
                }
            }

            if (el.filter("[data-text-highlight]").length) {
                val = val.replace(/\*\*(.*?)\*\*/gm, '<b>$1</b>');
                template.html(val);
            } else if (el.filter("[data-tinymce]").length) {
                template.html(val);
            } else {
                template.text(val);
            }
        });

        selector.on("change", "[data-hide]", function () {
            let el = $(this);
            let id = el.data("hide");
            let template = selector.find(`[data-template-edit="${id}"]`);
            let checked = $(this).prop('checked');

            template.closest('.elm_candidate').toggle(!checked);

            if (selector.find(`[data-template-hide="${id}"]`).length) {
                selector.find(`[data-template-hide="${id}"]`).toggle(!checked);
            }
        });

        selector.on("change", "[data-select-edit]", function () {
            let el = $(this);
            let id = el.data("select-edit");
            let val = el.val();
            let template = selector.find(`[data-template-edit="${id}"]`);
            template.text(val);
        });

        selector.on("change", "[data-text-heading]", function () {
            let el = $(this);
            let id = el.closest(".part_ui_input").find("[data-text-edit]").data("text-edit");
            let val = el.val();

            if (val === "bold") {
                selector.find(`[data-template-edit="${id}"]`).parent().removeClass("elm_head_title").addClass("elm_head_heading");
            } else {
                selector.find(`[data-template-edit="${id}"]`).parent().removeClass("elm_head_heading").addClass("elm_head_title");
            }

            // selector.find(`[data-template-edit="${id}"]`).parent();
        });

        selector.on("change", "[data-text-color]", function () {
            let el = $(this);
            let id = el.closest(".part_ui_checkbox").find("[data-text-color]").data("text-color");

            if (el.prop("checked") === true) {
                selector.find(`[data-template-edit="${id}"]`).addClass("mod--invert")
            } else {
                selector.find(`[data-template-edit="${id}"]`).removeClass("mod--invert")
            }

            // selector.find(`[data-template-edit="${id}"]`).parent();
        });

        selector.on("change", "[data-select-image]", function () {
            let el = $(this);
            let id = el.data("select-image");
            let val = el.val();
            let template = selector.find(`[data-template-edit="${id}"]`);
            template.attr('src', val);
        });

        $(".wrp_candidates [data-candidate-remove]").hide().last().show();

        if ($('[data-candidate-add]').length) {
            let el = $('[data-candidate-add]');
            let options = el.data("candidate-add");
            let items = selector.find(".part_form_controls").find(".wrp_candidates .part_item_candidate");
            let items_max = options["items-max"];
            let item_length = items.length;

            if (item_length === items_max) {
                el.hide();
            }
        }

        selector.on("click", "[data-candidate-add]", function () {
            let el = $(this);
            let options = el.data("candidate-add");
            let items = selector.find(".part_form_controls").find(".wrp_candidates .part_item_candidate");
            let items_max = options["items-max"];
            let items_photo = options["items-photo"];
            let items_remove_class_big = options["items-remove-class-big"];
            let item_length = items.length;
            let item = selector.find(".part_form_controls").find("[data-candidate-template]").html().replace(/{ind}/g, item_length + 1);

            if (item_length <= items_max) {
                if (item_length > items_photo - 1) {
                    selector.find(".part_form_controls").find(".wrp_candidates").append(item.replace("data-image-upload", "hidden"));
                } else {
                    selector.find(".part_form_controls").find(".wrp_candidates").append(item);
                }
            }
            if (item_length >= items_max - 1) {
                el.hide();
            }

            if (items_remove_class_big) {
                if (item_length + 1 > items_remove_class_big) {
                    $(".part_template_2018_c_text_a5_white").removeClass("mod--size-big");
                }
            }

            items.each(function () {
                $(this).find("[data-candidate-remove]").hide();
            });

            selector.find("[data-template-item]").filter(`[data-template-item="${item_length + 1}"]`).addClass("mod--active");

            autosize($('textarea:not(.autosize-disabled)'));
        });

        selector.on("click", "[data-candidate-remove]", function () {
            let el = $(this);
            let item = el.closest(".part_item_candidate");
            selector.find("[data-template-item]").filter(`[data-template-item="${item.index() + 1}"]`).removeClass("mod--active");
            item.prev().find("[data-candidate-remove]").show();
            $("[data-candidate-add]").show();
            item.remove();
        });

        selector.on("change", "[data-background]", function () {
            let el = $(this);

            if (el.val() === 'white') {
                $("[data-template-background]").css("background-image", "none");
                $("[data-template-background]").find("img").css("filter", "none");
            } else if (el.val() === 'blue') {
                $("[data-template-background]").css("background-image", 'url("http://layoutomat.cz/img/2018_c/grid_a3_person_bg.jpg")');
                $("[data-template-background]").find("img:not(.edited)").css("filter", "invert(1)");
            }
        });

        selector.on("click", "[data-dialog-files]", function () {
            image_edit = $(this).data('image-edit');
            image_output_width = $(this).data('output-width');
            image_output_height = $(this).data('output-height');
            image_output_same = $(this).data('output-same');
            template_id = $('[data-template-id]').data('template-id');

            nl_lib_dialog.ajax($(this).data("dialog-files"), function () {
                doc.find(".lib--dialog .part_ui_btn").nl_lib_ripple();
                $.getScript(cdnjs.dropzone).done(() => {
                    let selector_upload = doc.find(".lib--dialog [data-dropzone]");
                    if (selector_upload.length) {
                        let lib_dropzone = new Dropzone(selector_upload[0], {
                            "url": "/file/post",
                            "createImageThumbnails": true,
                            "acceptedFiles": ".jpg,.png",
                            "dictDefaultMessage": "",
                            "maxFiles": 1,
                            "previewTemplate": "<div class=\"dz-preview dz-file-preview\"><div class=\"dz-details\"><div class=\"dz-filename\"><span data-dz-name></span></div><div class=\"dz-size\" data-dz-size=\"Velikost:\"></div></div><div class=\"dz-progress\"><span class=\"dz-upload\" data-dz-uploadprogress></span></div><div class=\"dz-actions\"><a class=\"dz-remove\" data-dz-remove><i class=\"icon icon--delete\"></i></a></div></div><div class=\"dz-error-message\"><span data-dz-errormessage></span></div>"
                        });

                        lib_dropzone.on("success", function () {
                            nl_lib_dialog.ajax("/templates/edit/" + template_id + "?do=dialogEdit", function () {
                                let dialog_selector = $(".lib--dialog");
                                dialog_selector.find(".elm_dialog_background").css("pointer-events", "none");
                                $('[data-toggle-crop]').click();
                            });
                        });

                        lib_dropzone.on("reset", function () {
                            selector_upload.parent().find(".part_ui_alert").remove();
                        });

                        lib_dropzone.on("error", function (r) {
                            selector_upload.parent().find(".part_ui_alert").remove();
                            selector_upload.after(`
                                <div class="part_ui_alert mod--contrast mod--error" style="position: absolute;"><span>${r.xhr.response}</span></div>
                            `)
                        });
                    }
                });
            });
        });

        doc.on("click", "[data-dialog-edit]", function () {
            let $elm = $(this);

            nl_lib_dialog.ajax($elm.data("dialog-edit"), function () {
                fn_lib_croppie();
            });
        });

        doc.on("click", "[data-toggle-crop]", function () {
            let dialog_selector = $(".lib--dialog");
            if (dialog_selector.find(".mod--preview").length) {

                dialog_selector.find("[data-edit-image]").removeClass("mod--preview");
                dialog_selector.find("[data-edit-background]").addClass("mod--disabled");
                dialog_selector.find("[data-edit-crop]").removeClass("mod--disabled").find("input").removeAttr("disabled");
                fn_lib_croppie();
            }

            $.ajax({
                method: 'post',
                data: {do: 'activateImage'}
            });
        });

        doc.on("click", "[data-toggle-editor]", function () {
            let clipping = $("#clipping-magic-container");
            let dialog_selector = $(".lib--dialog");

            clipping.toggleClass("mod--hide");
            $(".lib--clippingmagic").toggleClass("mod--hide");

            if (!clipping.hasClass("mod--hide")) {
                dialog_selector.find(".part_dialog").fadeOut(300);
            }
        });

        doc.on("click", "[data-toggle-edit]", function () {
            let elm = $(this);
            let dialog_selector = $(".lib--dialog");
            elm.addClass("mod--loading");
            let errorsArray = ClippingMagic.initialize({apiId: 6476});
            if (errorsArray.length > 0) {
                alert("Omlouváme se editor nelze použít, váš prohlížeč nepodporuje následující funkce: \n\n " + errorsArray.join("\n "));
            } else {
                $.ajax({
                    method: 'get',
                    data: {do: 'clippingMagic'},
                    dataType: 'json'
                }).done(function (response) {
                    let interval = setInterval(function () {
                        if ($("#clipping-magic-container").length) {
                            clearInterval(interval);

                            dialog_selector.find(".part_dialog").fadeOut(300);

                            body.append(`
                                                        <div class="lib--clippingmagic">
                                                            <div class="elm_btns mod--row mod--row-horizontal mod--row-flex">
                                                                <div class="col"><a href="/help-editor/" class="part_ui_btn part_ui_btn_primary" target="_blank"><span class="icon icon--circle-help"></span><span>Nápověda</span></a></div>
                                                            </div>
                                                        </div>
                                                    `);
                            elm.removeClass("mod--loading");
                        }
                    }, 1);

                    ClippingMagic.edit({
                        "image": {
                            "id": parseInt(response.id),
                            "secret": response.secret
                        },
                        "locale": "en-US"
                    }, function (response) {
                        $(".lib--clippingmagic").remove();
                        dialog_selector.addClass("mod--loading");
                        if (response.event === "result-generated") {
                            nl_lib_dialog.ajax("/templates/edit/" + template_id + "?do=dialogEdit&step=2&refresh=1", function () {
                                fn_lib_croppie();
                                dialog_selector.removeClass("mod--loading");
                            });
                        } else {
                            nl_lib_dialog.ajax("/templates/edit/" + template_id + "?do=dialogEdit&step=2", function () {
                                fn_lib_croppie();
                                dialog_selector.removeClass("mod--loading");
                            });
                        }
                        doc.off("click", "[data-toggle-editor]");
                    });
                });
            }
        });

        function fn_lib_croppie() {
            $.getStyle(cdnjs.croppie_css);
            $.getScript(cdnjs.croppie).done(function () {
                let dialog_selector = $(".lib--dialog");
                if (!dialog_selector.find(".mod--preview").length) {
                    let image = dialog_selector.find("[data-lib-croppie]");
                    let options = image.data("lib-croppie");

                    if (image_output_same) {
                        options.viewport.width = image_output_width;
                        options.viewport.height = image_output_height;
                    }

                    image.croppie(options);

                    $(".croppie-container .cr-viewport").css(`background-image`, `url("${options["silhouette"]}")`);

                    dialog_selector.on("input", "[data-lib-croppie-zoom]", function () {
                        let el = $(this);
                        let val = el.val();
                        image.croppie("setZoom", val);
                    });

                    dialog_selector.on("click", "[data-apply-crop]", function () {
                        let elm = $(this);
                        elm.addClass("mod--loading");
                        image.croppie('result', {type: 'blob', size: {width: image_output_width, height: image_output_height}, format: 'png'}).then(function (blob) {
                            let data = new FormData();
                            data.append('file', blob);

                            $.ajax({
                                url: '/templates/edit?do=saveCrop',
                                method: 'post',
                                data: data,
                                dataType: 'json',
                                contentType: false,
                                processData: false
                            }).done(function (response) {
                                let $elm = $('[data-template-edit="' + image_edit + '"]');
                                if ($elm.is('img')) {
                                    $elm.attr('src', response.src).addClass('edited').css("filter", "none");
                                } else {
                                    $elm.css('background-image', 'url(' + response.src + ')');
                                }
                                $('[name="' + image_edit + '"]').val($('[data-edit-image]').find('img').attr('src'));

                                nl_lib_dialog.close();
                                elm.removeClass("mod--loading");
                            });
                        });
                    });
                }
            });
        }

        doc.on("click", "[data-set-image]", function () {
            $('[data-set-image]').removeClass('mod--active');
            $(this).addClass('mod--active');

            let $elm = $('[data-template-edit="' + $(this).data('image-edit') + '"]');
            $elm.css('background-image', 'url(' + $(this).attr('src') + ')');
        });

        doc.on('click', '[data-template-print]', function (e) {
            e.preventDefault();
            let html = $('[data-html]').html();
            let url = $(this).data('template-print');

            $.ajax({
                method: 'post',
                dataType: "json",
                data: {html: html},
                url: url,
            }).done(function(data) {
                nl_lib_dialog.open(data.dialog);
            });
        });

        doc.on('click', '[data-image-remove]', function () {
            let elm = $(this);
            $('[data-template-edit="' + elm.data('image-remove') + '"]').attr('src', '');
        });

        typeof tinymce !== "undefined" && tinymce.init({
            selector: '[data-tinymce]',
            toolbar: 'undo redo | bold italic underline',
            height: 150,
            menubar: false,
            branding: false,
            statusbar: false,
            forced_root_block : false,
            // content_css: 'css/content.css',
            init_instance_callback: function (editor) {
                editor.on('KeyUp Change Undo Redo', function (e) {
                    $(editor.getElement()).val(editor.getContent());
                    $(editor.getElement()).trigger('input');
                });
            }
        });
    }
})();
